<template>
  <div>
    <Head />
    <Floating />
    <div class="wrapper home" v-if="HomeData">
      <section class="banner" v-if="HomeData.Index_Slide">
        <slick class="marquee" :options="{
          autoplay: true,
          autoplaySpeed: 5000,
        }">
          <div class="box" v-for="Post in HomeData.Index_Slide.Posts" :key="Post.PId">
            <router-link :to="{ name: 'Post', params: { PId: Post.PId }}"><img :src="HomeData.SiteUrl + Post.Image" alt="" /></router-link>
          </div>
        </slick>
      </section>
      <div class="main_bg">
        <!-- 五個圖示按鈕 -->
        <section class="btn_box" v-if="HomeData.Index_Link">
          <div class="container">
            <ul class="list">
              <li v-for="Index_Link in HomeData.Index_Link">
                <a href="javascript:void(0)" @click="goto(Index_Link.Menu)">
                  <div class="img_box">
                    <img :src="HomeData.SiteUrl + Index_Link.Menu.Image" alt="">
                  </div>
                  <p>{{ Index_Link.Menu.Title }}</p>
                </a>
              </li>
            </ul>
          </div>
        </section>

        <section class="news">
          <div class="container">
            <div class="title">
              <h1>{{ $t("最新消息") }}</h1>
              <div class="btn_box">
                <button v-for="(Index_News, index) in HomeData.Index_News" :key="index"
                  :class="NewsIndex == index ? 'active' : ''" @click="NewsIndex = index" style="cursor: pointer;">{{
                    Index_News.Category.CategoryName }}</button>
              </div>
            </div>
            <div class="bg">
              <ul v-for="(Index_News, index) in HomeData.Index_News" :key="index"
                :class="NewsIndex == index ? 'show' : ''" class="list">
                <li v-for="Post in Index_News.Posts" :key="Post.PId">
                  <router-link :to="{ name: 'Post', params: { PId: Post.PId }}">
                    <div class="tag_box" v-if="Post.IsTop || Post.IsNews || Post.IsAnnouncement">
                      <div class="tag" v-if="Post.IsTop">置頂</div>
                      <div class="tag" v-if="Post.IsNews">新聞</div>
                      <div class="tag" v-if="Post.IsAnnouncement">公告</div>
                    </div>
                    <div class="img_box">
                      <div class="img">
                        <img :src="HomeData.SiteUrl + Post.Image" alt="" v-if="Post.Image">
                        <img src="/img/img_1.png" alt="" v-if="!Post.Image">
                      </div>
                    </div>
                    <div class="text">
                      <p class="time">{{ Post.PublicStartTime | timeString("YYYY-MM-DD") }}</p>
                      <p>{{ Post.Title }}</p>
                    </div>
                  </router-link>
                </li>
                <div class="more_btn">
                  <button @click="gotoCategoryByCId(Index_News.Category.CId)" style="cursor: pointer;">MORE</button>
                </div>
              </ul>
            </div>
          </div>
        </section>

        <section class="service">
          <div class="container">
            <div class="title">
              <h1>{{ $t("常用服務") }}</h1>
            </div>
            <div class="box">
              <div class="block_1" v-if="HomeData.Index_Service_Pet_1">
                <div class="bg">
                  <img class="img_2" src="/img/service_icon2.svg" alt="">
                  <p>{{ $t("寵物專區") }}</p>
                  <ul class="list">
                    <div>
                      <li v-for="(MenuData, index) in HomeData.Index_Service_Pet_1" :key="index">
                        <a href="javascript:void(0)" @click="goto(MenuData.Menu)">{{ MenuData.Menu.Title }}</a>
                      </li>
                    </div>
                    <div>
                      <li v-for="(MenuData, index) in HomeData.Index_Service_Pet_2" :key="index">
                        <a href="javascript:void(0)" @click="goto(MenuData.Menu)">{{ MenuData.Menu.Title }}</a>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
              <div class="block_2" v-if="HomeData.Index_Service_Allowance || HomeData.Index_Service_Prevention">
                <div class="bg" v-if="HomeData.Index_Service_Allowance">
                  <img class="img_1" src="/img/service_icon1.svg" alt="">
                  <p>{{ $t("補助專區") }}</p>
                  <ul class="list">
                    <li v-for="(MenuData, index) in HomeData.Index_Service_Allowance" :key="index">
                      <a href="javascript:void(0)" @click="goto(MenuData.Menu)">{{ MenuData.Menu.Title }}</a>
                    </li>
                  </ul>
                </div>
                <div class="bg" v-if="HomeData.Index_Service_Prevention">
                  <img class="img_3" src="/img/service_icon3.svg" alt="">
                  <p>{{ $t("防疫專區") }}</p>
                  <ul class="list">
                    <li v-for="(MenuData, index) in HomeData.Index_Service_Prevention" :key="index">
                      <a href="javascript:void(0)" @click="goto(MenuData.Menu)">{{ MenuData.Menu.Title }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="link_box" v-if="HomeData.Index_RelaLink">
          <div class="container">
            <div class="title">
              <h1>{{ $t("相關連結") }}</h1>
            </div>
            <div class="bg">
              <slick class="marquee_link" :options="{
                arrows: true,
                dots: false,
                slidesToShow: 3,
                slidesToScroll: 1,
                responsive: [
                  {
                    breakpoint: 991.98,
                    settings: {
                    }
                  },
                  {
                    breakpoint: 767.98,
                    settings: {
                      slidesToShow: 2,
                    }
                  },
                  {
                    breakpoint: 575.98,
                    settings: {
                      slidesToShow: 1,
                    }
                  },
                ]
              }">
                <div class="box" v-for="Post in HomeData.Index_RelaLink.Posts" :key="Post.PId">
                  <router-link :to="{ name: 'Post', params: { PId: Post.PId }}">
                    <img :src="HomeData.SiteUrl + Post.Image" alt="" v-if="Post.Image" />
                    <img src="/img/img_1.png" alt="" v-if="!Post.Image" />
                  </router-link>
                </div>
              </slick>
            </div>
          </div>
        </section>
      </div>

    </div>
    <FooterMenu />
    <Footer />
  </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Floating from "@/components/Floating.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import Slick from "vue-slick";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
    最新消息: "最新消息",
    常用服務: "常用服務",
    寵物專區: "寵物專區",
    補助專區: "補助專區",
    防疫專區: "防疫專區",
    相關連結: "相關連結",
  },
  en: {
    最新消息: "News",
    常用服務: "Services",
    寵物專區: "Pet",
    補助專區: "Subsidy",
    防疫專區: "Epidemic Prevention",
    相關連結: "Related Links",
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

export default {
  name: 'Home',
  i18n,
  components: {
    Head,
    Floating,
    FooterMenu,
    Footer,
    Slick,
  },
  data() {
    return {
      HomeData: {},
      NewsIndex: 0,
      local: "zh_tw",
    };
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Home",
        {
          params: { lang: $cookies.get("aow_site_lang") },
        }
      )
      .then((response) => {
        this.HomeData = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    // 調整背景頁面高度  
    $(function () {
      var h = $('html body').height();
      $('.wrapper .main_bg').css('min-height', h);
      // console.log(h);
    });
    if ($cookies.isKey("aow_site_lang")) {
      i18n.locale = $cookies.get("aow_site_lang");
      this.local = $cookies.get("aow_site_lang");
    } else {
      i18n.locale = $cookies.get("aow_site_lang");
      this.local = "zh_tw";
    }
  },
  methods: {
    gotoCategoryByCId: function (CId) {
      this.$router.push({
        name: "Category",
        params: { CId: CId },
      });
    },
    gotoPostByPId: function (PId) {
      this.$router.push({
        name: "Post",
        params: { PId: PId },
      });
    },
    gotoUrl: function (Url, Blank) {
      if (Blank) {
        window.open(Url, "_blank");
      } else {
        location.href = Url;
      }
    },
    gotoPost(Post) {
      if (Post.Type == 1) {
        if (Post.Blank) {
          window.open(Post.Url, "_blank");
        } else {
          location.href = Post.Url;
        }
      } else {
        this.$router.push({
          name: "Post",
          params: { PId: Post.PId },
        });
      }
    },
    goto: function (MenuData) {
      if (MenuData.Type == 1) {
        this.gotoCategoryByCId(MenuData.CId);
      } else if (MenuData.Type == 2) {
        this.gotoPostByPId(MenuData.PId);
      } else if (MenuData.Type == 3) {
        this.gotoUrl(MenuData.Url, MenuData.Blank);
      }
    }
  },
}
</script>
